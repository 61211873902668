import React, { Fragment } from 'react'
import { Copy, CardCampaign, Hero, Loading, MoreLink, SectionFull } from '@website2018/da-dobsonville';
import { Link } from 'react-router-dom';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { noComma } from '../../common/utils';
import Asides from '../../components/Asides/Asides';
import MetaHeader from '../../components/Meta/MetaHeader';
import { Redirect } from 'react-router-dom';

class CareersContainer extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      openModal: false,
      selected: null
    }
  }

  componentDidMount() {
  }

  handleCloseModalApply = () => {
    this.setState({
      openModalApply: false,
      selected: null
    })
  }

  handleCloseModalInfo = () => {
    this.setState({
      openModalInfo: false,
      selected: null
    })
  }

  handleOpenModalApply = (career) => {
    this.setState({
      openModalApply: true,
      selected: career
    })
  }

  handleOpenModalInfo = (career) => {
    this.setState({
      openModalInfo: true,
      selected: career
    })
  }

  render() {
    const { error, loading, page: p, jobs = [] } = this.props;

    if (loading) return <Loading/>;
    if (error || !p) return <Redirect to={'/not-found'}/>;


    return ([
      <MetaHeader p={p} />,
      <Hero
        imageUrl={p.hero && p.hero.background_image ? p.hero.background_image.url : null}
        title={p.hero && p.hero.headline ? p.hero.headline : p.title.rendered}
        bgPosition={ p.hero && p.hero.focus_point && noComma(p.hero.focus_point) }
        mb={p.hero && p.hero.background_image && p.hero.background_image.url ? "large" : "none"}
      />,
      <Fragment>
        { p.content.rendered &&
          <SectionFull mt={"none"}>
            <div className={"col-md-7"}>
              <Copy intro html={p.content.rendered} />
            </div>
            <div className="col-lg-4 ml-auto" pl="none">
              { p.asides &&
                <Asides asides={p.asides} />
              }
            </div>
          </SectionFull>
        }
      </Fragment>,
      <SectionFull color={"red"} title={"Current Vacant Positions"}>
        <div className="col-md-7">
        {
          jobs.map(job => (
            <Link to={`/jobs/${job.slug}`}>
              <CardCampaign
                key={job.id}
                title={job.title.rendered}
                meta={job.meta.job.province && job.meta.job.province.length ? `${job.meta.job.province.map(p => p.name).join(' | ')}` : null}
                paragraph={job.excerpt.rendered}
                renderCta={
                  (hover) => <MoreLink hover={hover} color={"red"}>View More</MoreLink>
                }
              >
                <Copy mb="none" size={"small"}><strong>Closing Date: {job.meta.job.date}</strong></Copy>
                <Copy size={"small"}><strong>Reference: {job.meta.job.reference}</strong></Copy>
              </CardCampaign>
            </Link>

          ))
        }
        </div>

      </SectionFull>
    ])
  }
}


const PAGE_QUERY = gql`
  query getPage($slug: String!) {
    page(slug:$slug) {
      id
      slug
      seo
      template
      title {
        rendered
      }
      content {
        rendered
      }
      excerpt {
        rendered
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
      }
      hero{
        headline
        cta_type
        cta_group{
          title
          link_label
          link_url
        }
        background_image{
          url
        }
        focus_point
      }
    },
    jobs {
      id
      slug
      title {
        rendered
      }
      excerpt {
        rendered
      }
      content {
        rendered
      }
      meta {
        job {
          reference
          date
          province {
            name
          }
        }
      }
    }
  }
`;

const withPage = graphql(PAGE_QUERY, {
  options: (props) => {
    return ({
      variables: {
        slug: 'jobs-at-the-da'
      }
    })
  },
  props: ({ data }) => ({ ...data }),
});

export default withPage(CareersContainer);

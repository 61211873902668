import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { Loading } from '@website2018/da-dobsonville';
import MemberTemplate from '../../components/People/MemberTemplate'
import { Redirect } from 'react-router-dom';

class MemberContainer extends React.Component {

    render() {
      const { error, loading, member } = this.props;

      if (loading) return <Loading/>;
      if (error || !member) return <Redirect to={'/not-found'}/>;

      return ([

        <MemberTemplate {...this.props} />
      ]);
    }

}

const MEMBER_QUERY = gql`
  query getMember($slug: String!) {
    page(slug: "our-people") {
      id
      slug
      title {
        rendered
      }
      children {
        id
        slug
        title {
          rendered
        }
        content {
          rendered
        }
      }
    },
    member(slug:$slug) {
      id
      seo
      slug
      content {
        rendered
      }
      title {
        rendered
      }
      publicRep{
        email
        social{
          twitter
          facebook
          instagram
          linkedin
        }
        offices{
          description
          address
          phone
        }
        federal
        government
        provincial
      }
      role
      facebook
      twitter
      featured_media {
        id
        source_url
        title {
          rendered
        }
      }
    }
  }
`;

const withMember = graphql(MEMBER_QUERY, {
  options: (props) => {
    return ({
      variables: {
        slug: props.match.params.slug
      }
    })
  },
  props: ({ data, post }) => ({ ...data }),
});

export default MemberContainer = withMember(MemberContainer);

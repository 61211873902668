import React from "react";
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { Loading } from "@website2018/da-dobsonville";
import BaseGovernment from '../../components/Government/Base'
import { Redirect } from "react-router-dom";

class GovernContainer extends React.Component {
  render() {
    const {
      error,
      govern,
      loading,
      match: {
        params: { slug }
      }
    } = this.props;

    if (loading) return <Loading />;
    if (error || !govern) return <Redirect to={"/not-found"} />;

    return <BaseGovernment govern={govern} slug={slug} />;
  }
}

const POST_QUERY = gql`
  query getGovernment($slug: String!) {
    govern(slug: $slug) {
      id
      seo
      excerpt {
        rendered
      }
      title {
        rendered
      }
      content {
        rendered
      }
      featured_media {
        source_url
      }
      hero {
        headline
        background_image {
          url
        }
        focus_point
      }
      highlight {
        people {
          id
          slug
          title {
            rendered
          }
          excerpt {
            rendered
          }
          role
          facebook
          twitter
          featured_media {
            id
            source_url
            title {
              rendered
            }
            media_details {
              sizes {
                large { source_url }
              }
            }
          }
        }
      }
      list {
        hasList
        section_heading
        collapsible
        list_items {
          title
          text
          image {
            url
            sizes { medium_large }
          }
          icon
        }
      }
      asides {
        id
        title {
          rendered
        }
        content {
          rendered
        }
        aside_options
      }
      sectionPeople
      testimonials {
        section_heading
        testimonial {
          headline
          attribution
          photo {
            url
            sizes { medium }
          }
          text
        }
      }
      stickers {
        title
        icon
        background {
          url
        }
        color
        text
      }
      posts {
        id
        date
        slug
        formattedDate
        title {
          rendered
        }
        category {
          id
          name
        }
      }
      related {
        id
        title {
          rendered
        }
        slug
      }
      videos {
        id
        videoId
        title {
          rendered
        }
        excerpt {
          rendered
        }
        category {
          id
          name
        }
        featured_media {
          id
          source_url
          title {
            rendered
          }
        }
      }
      people {
        title
        prefname
        firstname
        lastname
        portfolio
        government
        email
        cellphone
      }
      stories {
        title
        items {
          content
          background
          icon
          image {
            title
            sizes {
              large
            }
          }
        }
      }
    }
  }
`;

const withPost = graphql(POST_QUERY, {
  options: props => ({
    variables: { slug: props.match.params.slug }
  }),
  props: ({ data, post }) => ({ ...data })
});

export default withPost(GovernContainer);

import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { Loading } from '@website2018/da-dobsonville';
import { Redirect } from 'react-router-dom';

import Article from '../../components/Newsroom/Article';
import MetaHeader from '../../components/Meta/MetaHeader';
import Asides from '../../components/Asides/Asides';

class PostContainer extends React.Component {
  render() {
    const { loading, post, asides, taxonomy, error } = this.props;
    if (loading) return <Loading/>;
    if (error || !post) return <Redirect to={'/not-found'}/>;


    return ([
      <MetaHeader p={post} />,
      <Article post={post} taxonomy={taxonomy}/>,
      <Asides fullWidth={true} asides={asides} />
    ]);

  }
}

const POST_QUERY = gql`
  query getPost($slug: String!, $taxInput: TaxInputQuery) {
    asides(category: "news") {
      id
      title {
        rendered
      }
      content {
        rendered
      }
      aside_options
    }
    post(slug:$slug) {
      id
      formattedDate
      seo
      title {
        rendered
      }
      excerpt {
        rendered
      }
      content {
        rendered
      }
      featured_media {
        id
        source_url
  			title {
  			  rendered
  			}
      }
      category {
        id
        slug
        taxonomy
        name
      }
      tag {
        id
        slug
        taxonomy
        name
      }
      meta {
        person {
          member
          role
          hasMember
        }
      }
      province {
        name
      }
    }
    taxonomy(input:$taxInput) {
      id
      name
      slug
      taxonomy
    }
  },

`;

const withPost = graphql(POST_QUERY, {
  options: (props) => ({
    variables: { slug: props.match.params.slug, taxInput: { taxonomy: 'categories' } }
  }),
  props: ({ data, post }) => ({ ...data }),
});

export default withPost(PostContainer);

import React from "react";
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { Loading } from "@website2018/da-dobsonville";
import { Redirect } from "react-router-dom";

import MetaHeader from '../../components/Meta/MetaHeader';
import PolicyTemplate from '../../components/Policy/Base';

class PolicyContainer extends React.Component {
  render() {
    const { asides, error, loading, page, policy } = this.props;

    if (loading) return <Loading />;
    if (error || !policy) return <Redirect to={"/not-found"} />;

    return [
      <MetaHeader p={policy} />,
      <PolicyTemplate policy={policy} page={page} asides={asides} />,
    ];
  }
}

const POLICY_QUERY = gql`
  query getPolicy($slug: String!) {
    asides(category: "policies") {
      id
      title {
        rendered
      }
      content {
        rendered
      }
      aside_options
    }
    page(slug: "policies") {
      id
      slug
      title {
        rendered
      }
      acf
      parent {
        slug
        title {
          rendered
        }
        children {
          slug
          title {
            rendered
          }
          parent {
            slug
          }
        }
      }
    }
    policy(slug: $slug) {
      id
      seo
      acf
      title {
        rendered
      }
      excerpt {
        rendered
      }
      content {
        rendered
      }
      category {
        id
        name
        slug
      }
      download
      stickers {
        title
        icon
        background {
          url
        }
        color
        text
      }
    }
  }
`;

const withPolicy = graphql(POLICY_QUERY, {
  options: (props) => ({
    variables: { slug: props.match.params.slug },
  }),
  props: ({ data, post }) => ({ ...data }),
});

export default withPolicy(PolicyContainer);
